import React from "react"

const Test = () => {
  return (
    <div>
      test
    </div>
  )
};

export default Test
