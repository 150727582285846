import React from "react"

import Auth from "../components/Auth";
const Dempo = () => {
  return (
    <>
<Auth/>

    </>
  )
};

export default Dempo
